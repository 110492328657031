import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Logique de routage

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'worker/:uuid/daily-mileage',
    loadChildren: () => import('./mileage-input-form/mileage-input-form.module').then( m => m.MileageInputFormPageModule)
  },
  {
    path: 'worker/:uuid/weekly-mileage-view',
    loadChildren: () => import('./weekly-mileage-view/weekly-mileage-view.module').then( m => m.WeeklyMileageViewPageModule)
  },
  {
    path: 'worker/:uuid/monthly-mileage-validation',
    loadChildren: () => import('./worker-monthly-validation/worker-monthly-validation.module').then( m => m.WorkerMonthlyValidationPageModule)
  },
  {
    path: 'worker/:uuid/monthly-mileage-review',
    loadChildren: () => import('./manager-monthly-validation/manager-monthly-validation.module').then( m => m.ManagerMonthlyValidationPageModule)
  },
  {
    path: 'worker/:uuid/monthly-mileage-validated',
    loadChildren: () => import('./worker-monthly-validated/worker-monthly-validated.module').then( m => m.WorkerMonthlyValidatedPageModule)
  },
  {
    path: 'worker/:uuid/monthly-mileage-reviewed',
    loadChildren: () => import('./manager-monthly-validated/manager-monthly-validated.module').then( m => m.ManagerMonthlyValidatedPageModule)
  },
  {
    path: 'worker/:uuid/manager-mileage-view',
    loadChildren: () => import('./manager-mileage-view/manager-mileage-view.module').then( m => m.ManagerMileageViewPageModule)
  }
];

// @ts-ignore
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
