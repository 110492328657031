import {Agency} from './model/Agency';

export class GlobalComponent {

  public static appUrl = 'https://www.example.com/';
  public static appName = 'Example Site';
  public static appLogo = 'assets/images/logo.png';

  public agency: Agency;

  constructor() {
  }

  public initAgency(agency: Agency){
    this.agency=agency;
  }
}
